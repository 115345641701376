@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');

body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: inherit;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.radio-btn {
  margin-top: 5px;
  width: 16px;
  height: 16px;
}
