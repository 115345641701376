.main-container {
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #c6d2dd 0%, #4a6f94 100%);
  position: relative;
  /* z-index: -1; */
}

/* Header */

.top-nav {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0px 0px 100px 0px;
  background-image: linear-gradient(to right, white, #f8d47c);
  height: 100%;
}

.top-nav-survey {
  display: flex;
  align-items: center;
  width: 100%;
  background-image: linear-gradient(to bottom, #f4f4f4, #cfd9e5);
  height: 100%;
  padding-left: 10%;
}

.top-sidebar {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0px 0px 100px 0px;
  background-image: linear-gradient(to right, white, #f8d47c);
  height: 60px;
}

.nav-logo {
  max-width: 30%;
  padding-left: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.resource-link:hover {
  text-decoration: underline !important;
}

.sidebar-hover:hover {
  color: #f8d47c !important;
}

@media (max-width: 700px) {
  .nav-logo {
    max-width: 95%;
  }
}

.stick-top {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.header-bg {
  background-image: linear-gradient(to bottom, #f4f4f4, #cfd9e5);
}

/* Login  */

.mainLogo {
  width: 200px;
  margin-top: 50px;
}

@media (max-width: 700px) {
  .mainLogo {
    width: 50%;
    margin: auto;
    margin-top: 80px;
  }
}

.form-input {
  border-radius: 20px;
  background-color: #b0ac8c;
  color: white;
  padding: 10px;
  border: none;
  width: 30%;
  margin-bottom: 15px;
}

@media (max-width: 700px) {
  .form-input {
    width: 70%;
  }
}

.custom-btn {
  border-radius: 25px;
  border: 2px solid white;
  width: 20%;
  margin: auto;
  background-color: transparent;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  margin-top: 30px;
}

@media (max-width: 700px) {
  .custom-btn {
    width: 30%;
  }
}

.toggle-password {
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
}

.forgot-password {
  color: white;
  margin-top: 40px;
  cursor: pointer;
}

/* Modules */

.signout-btn {
  border-radius: 25px;
  border: 2px solid #3b638c;
  width: 100%;
  background-color: transparent;
  padding: 4px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.olc-banner {
  width: 80%;
  margin-top: 6%;
}

.thirty-width {
  width: 30%;
}

.fifty-width {
  width: 55%;
}

.seventy-width {
  width: 65%;
}

.margin-big-width {
  margin: 0 auto;
  margin-left: 15%;
  margin-right: 15%;
}

.ask-expert-padding {
  padding-top: 10%;
}

.isNotCollapsed {
  margin-left: 250px;
}

@media (max-width: 900px) {
  .olc-banner {
    margin-top: 10%;
  }
}

@media screen and (max-width: 750px) {
  .fifty-width {
    width: 80%;
  }

  .thirty-width {
    width: 40%;
  }

  .olc-banner {
    width: 90%;
    margin-top: 20%;
  }

  .seventy-width {
    width: 90%;
  }

  .ask-expert-padding {
    padding-top: 20%;
  }
}

.certificate-left-text-container {
  width: 55%;
}

@media screen and (max-width: 600px) {
  .olc-banner {
    width: 90%;
    margin-top: 33%;
  }
  .ask-expert-padding {
    padding-top: 35%;
  }

  .small-font-mobile {
    font-size: 0.9rem;
  }

  .margin-big-width {
    margin-left: 5%;
    margin-right: 5%;
  }

  .isNotCollapsed {
    margin: 0;
  }
  .certificate-left-text-container {
    width: 100%;
  }

  .small-text-size {
    font-style: 'PT Serif';
    font-size: 6px;
  }

  .top-nav-survey {
    padding-left: 0;
  }
}

.logo-top {
  width: 100%;
}

.modules-header {
  text-align: center;
  color: #f8d47c;
  text-decoration: underline;
  margin-top: 30px;
}

.modules-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.card {
  flex-basis: calc(33.33% - 10px);
  background-color: #f5f5f5;
  margin-bottom: 20px;
  border-radius: 20px;
  /* background-image: url("../src/assets/module-0.png"); */
  height: 100%;
  background-size: cover;
  background-position: center center;
  width: 100%;
  border-radius: 20px;
}

.card-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 101%;
  padding-top: 35px;
  background-color: rgba(240, 212, 116, 0.8);
  border-radius: 20px;
}

.ask-expert {
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: bold;
  font-size: 14px;
}

.book-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.module {
  font-size: 24px;
  font-weight: 600;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0;
}

.instructor {
  font-size: 14px;
  text-decoration: underline;
  font-weight: 800;
}

.position {
  font-size: 14px;
  font-weight: 500;
}

.enter-btn {
  background-color: transparent;
  color: #02253e;
  border: none;
  border-radius: 5px;
  padding: 10px 40px;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: 2px solid #02253e;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  height: 45px;
}

.card-middle {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
}

.card-bottom {
  margin-top: 20%;
}

@media screen and (max-width: 968px) {
  .card {
    flex-basis: calc(50% - 10px);
  }
}

@media screen and (max-width: 768px) {
  .card {
    flex-basis: 100%;
  }

  .card-middle {
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
  }

  .ask-expert {
    top: 5px;
    right: 5px;
  }

  .enter-btn {
    bottom: 5px;
    right: 5px;
  }
}

.icon-ask {
  /* content: url("../src/assets/ask-the-expert.png"); */
  width: 38px;
}

.book-icon {
  /* content: url("../src/assets/icon.png"); */
  width: 38px;
  height: 39px;
}

.enter-icon {
  /* content: url("../src/assets/enter.png"); */
  width: 22px;
}

/* Ask Expert */

.ask-container {
  min-height: 100vh;
  background: linear-gradient(180deg, #c6d2dd 0%, #4a6f94 100%);
}

.question-btn {
  border-radius: 10px;
  border: 2px solid #102f47;
  width: 30%;
  /* margin: auto; */
  background-color: transparent;
  color: #102f47;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  /* margin-top: 5%; */
}

@media screen and (max-width: 700px) {
  .question-btn {
    width: 50%;
    padding: 10px;
    /* margin-top: 10%; */
  }
}

@media screen and (max-width: 992px) {
  .container {
    max-width: 960px !important;
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 720px !important;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
}

.forum-topics-bg {
  background-image: linear-gradient(to bottom, #7693ae, #3e658d);
  border-radius: 10px;
}

.forum-topic-bg {
  background-image: linear-gradient(to bottom, #fefefe, #d5d5d5);
  border-radius: 10px;
}

.theme-blue {
  color: #3b628d;
  text-decoration: none;
}

/* Question */

.theme-yellow {
  color: #f0d747;
}

/* Submit  */

.submit-container {
  min-height: 100vh;
  background: linear-gradient(180deg, #c6d2dd 0%, #4a6f94 100%);
}

.submit-question {
  max-width: 960px;
  margin: auto;
}

.submit-topic-bg {
  background-image: linear-gradient(to bottom, #7693ae, #3e658d);
  border-radius: 10px;
}

.btn-yellow-bg {
  border: 2px solid #ffe887 !important;
  background-color: #ffe887 !important;
  margin-top: 10px;
  border-radius: 20px !important;
}

/* Sidebar  */

/* .topbar-button {
  margin-left: 0
}

@media screen and (max-width: 992px) {
  .topbar-button {
    margin-left: 30%
  }
} */

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #3b638c;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  z-index: 100;
}

.sidebar.collapsed {
  transform: translateX(-100%);
}

.hamburger {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  padding: 25px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  transition: transform 0.3s ease-in-out;
  margin-left: 100%;
}

.hamburger:focus {
  outline: none;
}

.hamburger-icon {
  display: block;
  width: 20px;
  height: 3px;
  background-color: #333;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.hamburger-icon::before,
.hamburger-icon::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #333;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
}

.hamburger-icon::before {
  transform: translateY(-7px);
}

.hamburger-icon::after {
  transform: translateY(7px);
}

.sidebar.collapsed .hamburger-icon::before {
  transform: rotate(-45deg) translate(-5px, 5px);
}

.sidebar.collapsed .hamburger-icon::after {
  transform: rotate(45deg) translate(-5px, -5px);
}

.sidebar.collapsed .hamburger-icon {
  transform: translateX(calc(100% - 30px));
}

.sidebar-content {
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.sidebar-content img {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

.sidebar-content nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-content nav ul li {
  margin-bottom: 10px;
}

.sidebar-content nav ul li a {
  display: block;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.sidebar-item {
  width: 80%;
  display: block;
  padding: 12px 0px 12px 34px !important;
  text-align: left !important;
  outline: 0 !important;
  text-decoration: none;
  border-left: 5px solid transparent;
  color: #fff;
}

.sidebar-item-active {
  width: 80%;
  display: block;
  padding: 12px 0px 12px 34px !important;
  text-align: left !important;
  outline: 0 !important;
  text-decoration: none;
  border-left: 5px solid gold;
  color: #fff;
}

.side-logo {
  left: 48px;
  top: 0;
  width: 150px;
}

h3.sidebar-item {
  color: #3b638c;
  background-color: #f0d747;
  margin-top: 80%;
  border-radius: 0px 25px 25px 0px;
}

/* video  */

.flow-blue {
  color: #426b91;
}

.play-button {
  position: absolute;
  width: 35%;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%) !important;
  height: 100%;
}

.play-btn-img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12%;
  transform: translate(-50%, -50%) !important;
  animation: pulses 2s linear infinite;
  cursor: pointer;
}

.video-container {
  position: relative;
  padding-bottom: 51%;
  height: 0;
  overflow: hidden;
}

.video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-iframe {
  width: 100%;
  height: 100%;
}

.container-iframe {
  position: relative;
  overflow: hidden;
  width: 50%;
  padding-top: 30.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe-about-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.about-video {
  position: relative;
  padding-top: 50.25%;
  width: 100%;
  /* width: 125%; */
  /* height: 200px; */
}

.about-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.about-img {
  object-fit: contain;
  width: 170px;
}

/* resources  */

.materials-bg {
  background-image: linear-gradient(to bottom, #7693ae, #3e658d);
  border-radius: 20px;
}

.material-content-bg:last-child {
  margin-bottom: 0px;
}

.material-content-bg {
  background-image: linear-gradient(to bottom, #fefefe, #d5d5d5);
  border-radius: 10px;
  margin-bottom: 15px;
}

.material-title {
  font-weight: bold;
  color: #3b628d;
  font-size: 16px;
}

/* Assessment */

.assessment-header {
  color: #102f47;
  text-decoration: none;
  position: relative;
}

.questions-bg {
  background-color: #fffdf7;
  border-radius: 10px;
  padding: 20px;
}

.question-tag {
  padding: 8px 10px;
  border-radius: 0px 50px 50px 0px;
  background-color: #f0d747;
}

.pos-abs {
  position: absolute;
}

.questions-bg .question {
  margin-left: 4.5rem !important;
}

.question-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.015em;
  color: #3b638c;
  margin-top: 20px;
  text-align: left;
}

.options label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #3b638c;
}

.options input[type='radio'] {
  margin-right: 5px;
}

.options span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.015em;
  color: #3b638c;
}

.mobile-container {
  padding-left: 50px;
  padding-right: 50px;
}

@media screen and (max-width: 768px) {
  .mobile-container {
    padding-left: 0;
    padding-right: 0;
  }
}

/* register  */
.register-container {
  display: flex;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
}

.left-part-container {
  width: 50%;
  padding: 20px;
}

.left-part-container-login {
  width: 50%;
  padding: 20px;
}

.card-overlay-register {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(240, 212, 116, 0.8);
  border-radius: 20px;
}

.left-container {
  position: relative;
  padding: 15px;
  border-radius: 20px;
  background-image: url('../src/assets/register-img.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.right-container {
  width: 50%;
  padding: 20px;
}

.show-logo-in-mobile {
  display: none;
}

.show-textbox-in-mobile {
  display: none;
}

/* Mobile Styles */
@media (max-width: 767px) {
  .register-container {
    flex-direction: column;
  }

  .left-part-container {
    display: none;
  }

  .right-container {
    order: 1;
    width: auto;
  }

  .show-logo-in-mobile {
    display: block;
  }

  .show-textbox-in-mobile {
    display: block;
  }

  .hide-logo-in-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .auth-image {
    display: none;
  }
}

.icon {
  background-image: url('../src/assets/doctor-icon.png');
  height: 100px;
  width: 100px;
}

.register-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
}

.register-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #202020;
  text-align: left;
}

.register-input {
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
  margin-bottom: 1rem;
  border: 1px solid #dddddd;
  background-color: transparent;
}

.register-btn {
  background: #f0d474;
  border-radius: 10px;
  font-style: normal;
  font-size: 20px;
  text-align: center;
  padding: 7px;
  width: 100%;
  border: none;
}

.welcome-text {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  color: #202020;
  text-align: left;
}

.create-acc-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #525252;
  text-align: left;
  margin-bottom: 10px;
}

.right-container-text {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #202020;
  text-align: left;
}

.already-text {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #f0d474;
  text-align: left;
}

.checkbox-container {
  margin-bottom: 10px;
}

.checkbox-container label {
  display: block;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
}

/* Certificate */

.big-cert-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 5%;
}

@media screen and (max-width: 760px) {
  .big-cert-container {
    flex-direction: column;
  }
}

.congratulation-text {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 130%;
  letter-spacing: 0.015em;
  color: #ffffff;
  text-align: left;
}

.certificate-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.015em;
  color: #ffffff;
  text-align: left;
}

.congratulation-modal {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  color: #184788;
}

.congratulation-sub-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #184788;
}

.congratulation-desc-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #184788;
}

/* faq  */

.faq-container {
  display: flex;
  justify-content: center;
}

.faq-left-container {
  width: 35%;
  padding: 20px;
}

.faq-right-container {
  width: 35%;
  padding: 20px;
}

.panel-header {
  white-space: normal;
  word-wrap: break-word;
}
.faq-title {
  background-color: #f0d474;
  color: #3b638c;
  border-radius: 25px;
  margin: 30px auto;
  font-size: 30px;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  width: 50%;
}

/* About */

.about-title {
  background-color: #f0d474;
  color: #3b638c;
  border-radius: 25px;
  margin: 0 auto;
  padding: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.about-subtext {
  font-style: normal;
  font-weight: 500;
  align-items: center;
  text-align: center;
  color: #3b638c;
}

.about-video-wrapper {
  position: absolute;
  top: 0;
  left: 17%;
  width: 60%;
  height: 70%;
  display: flex;
}

.preview-btn {
  background: #f0d474;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  padding: 7px;
  margin: auto;
  width: 50%;
  margin-top: -30px;
}

.joinnow-btn {
  background: #1b2e48;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  padding: 7px;
  margin: auto;
  width: 20%;
  color: white;
  margin-top: 20px;
}

.custom-button {
  border-radius: 10px;

  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding: 7px;
  margin: auto;
  border: none;
}

.sub-header-faq {
  color: #3b638c;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 1rem;
}
.faq-question {
  color: #3b638c;
  font-weight: 600;
}

.divider {
  border: 1px solid #3b638c;
  width: 100%;
}

.faq-ans {
  padding: 0.5rem 0;
}

.see-more-btn {
  margin-bottom: -2.5rem;
  margin-top: 1rem;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: -1rem;
  margin-bottom: 0.5rem;
}

.survey-title {
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  color: #3b638c;
  color: #fff;
  text-align: center;
}

.primary-text-color {
  color: #3b638c;
}

.rating-number-alignment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.survey-rating-layout {
  display: grid;
  grid-template-columns: 17% 15% 40% 10% 12%; /* Adjust column widths as needed */
  align-items: center;
  margin: 15px 0;
}

@media screen and (max-width: 760px) {
  .about-title {
    width: 90%;
  }

  .faq-title {
    font-size: 21px;
    width: 90%;
  }

  .faq-container {
    display: flex;
    flex-direction: column;
  }

  .faq-left-container {
    width: 100%;
  }

  .faq-right-container {
    width: 100%;
  }

  .survey-rating-layout {
    display: grid;
    grid-template-columns: 0% 20% 57% 20% 0%; /* Adjust column widths as needed */
    align-items: center;
    margin: 15px 0;
  }
}
